<script lang="ts">
import { onMount } from 'svelte'

onMount(() => document.body.classList.add('contact-us-bg'))
</script>

<page-not-found>
  <div class="main-container">
    <h2 class="flex">404 Page Not Found</h2>
    <a href="/">Go back to Safety</a>
  </div>
</page-not-found>

<style lang="scss">
page-not-found {
  display: block;
  width: auto;
  height: auto;
  margin: 60px auto;
  max-width: 420px;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

h2,
div {
  margin-bottom: 32px;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 42px;
  }
}

a {
  display: inline-block;
  cursor: pointer;
  height: initial;
  width: 190px;
  padding: 10px 0;
  border: none;
  border-radius: 29px;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.32px;
  text-align: center;
  background: linear-gradient(
    96.98deg,
    #ff560e -34.51%,
    #ff001f 22.52%,
    #8802c8 94.43%,
    #6202fe 144.6%
  );
  background-size: 150% auto;
  color: var(--white);
  transition: all 0.2s ease-in-out;
  margin: 0 auto;

  &:hover {
    background-position: right center;
    color: inherit;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 18px;
    line-height: 21px;
  }
}

h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.32px;

  @media only screen and (min-width: 1024px) {
    font-size: 25px;
    line-height: 29px;
  }
}
</style>
